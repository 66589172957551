import React, { useState, useRef, useMemo, useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';

import useStyles from './CountPicker.styles';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';

import { generateCountConfig } from '../../TimePicker.utils';
import withGenerateClassName from '../../../../../themes/withGenerateClassName';

interface ICountPickerProps {
  start: number;
  end: number;
  value: any;
  onChange: any;
  className?: string;
  placeholder: string;
}

function CountPicker({
  start,
  end,
  value,
  onChange,
  className,
  placeholder,
}: ICountPickerProps) {
  const [popoverAnchor, setPopoverAnchor] = useState<
    null | Element | ((element: Element) => Element)
  >(null);
  const listItems = useMemo(
    () => generateCountConfig(start, end),
    [start, end]
  );
  const scrollContainerRef = useRef(null);
  const anchorRef = useRef(null);

  const isOpen = Boolean(popoverAnchor);

  const onOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setPopoverAnchor(event.currentTarget);
  };
  const onClose = () => setPopoverAnchor(null);

  const classes = useStyles({ isOpen });

  useEffect(() => {
    if (!popoverAnchor) return;
    setTimeout(() => {
      if (!scrollContainerRef || !scrollContainerRef.current) return;
      const el = scrollContainerRef.current as HTMLDivElement;
      el.scrollTop = Math.max((value - start - 1) * 21, 0);
    }, 200);
  }, [popoverAnchor]);

  const updateScroll = (_value: number) => {
    if (!scrollContainerRef || !scrollContainerRef.current) return;
    const el = scrollContainerRef.current as HTMLDivElement;
    el.scrollTop += _value;
  };

  const onSelectMenuItem = (_value: number) => {
    onChange(_value);
    onClose();
  };

  const updateCounter = (_value: number, inc = false) => {
    const count = Number(_value);
    if (isNaN(count)) return;
    const ans = inc ? count + 1 : count - 1;
    if (ans < start || ans > end) return;
    onChange(ans);
  };

  return (
    <>
      <div className={classnames(classes.anchor_container, className)}>
        <div
          className={classes.anchor_content}
          onClick={onOpen}
          ref={anchorRef}
        >
          {value || Number(value === start)
            ? String(value).padStart(2, '0')
            : placeholder}
        </div>
        <div className={classes.anchor_actions}>
          <IconButton
            disableRipple
            disableFocusRipple
            component="span"
            className={classes.anchor_action_btn_up}
            disabled={(!value && Number(value) !== 0) || value === end}
            onClick={() => updateCounter(value, true)}
          >
            <ArrowDropUpRoundedIcon
              viewBox="8.4 9.4 7.18 4.59"
              className={classes.anchor_action_svg}
            />
          </IconButton>
          <IconButton
            disableRipple
            disableFocusRipple
            component="span"
            className={classes.anchor_action_btn_down}
            disabled={!value || value - 1 < start}
            onClick={() => updateCounter(value, false)}
          >
            <ArrowDropDownRoundedIcon
              viewBox="8.4 10 7.18 4.59"
              className={classes.anchor_action_svg}
            />
          </IconButton>
        </div>
      </div>
      <Popover
        anchorEl={popoverAnchor}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        classes={{ paper: classes.menu_paper }}
      >
        <IconButton
          disableRipple
          disableFocusRipple
          component="span"
          className={classes.menu_action_btn_up}
          onClick={() => updateScroll(-21)}
        >
          <ArrowDropUpRoundedIcon className={classes.anchor_action_svg} />
        </IconButton>
        <div className={classes.menu_list} ref={scrollContainerRef}>
          {listItems.map((item) => (
            <div
              key={item.id}
              className={classnames(
                classes.menu_item,
                item.value === value && classes.menu_item_selected
              )}
              onClick={() => onSelectMenuItem(item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
        <IconButton
          disableRipple
          disableFocusRipple
          component="span"
          className={classes.menu_action_btn_down}
          onClick={() => updateScroll(21)}
        >
          <ArrowDropDownRoundedIcon className={classes.anchor_action_svg} />
        </IconButton>
      </Popover>
    </>
  );
}

export default withGenerateClassName(CountPicker);
