import { FC } from 'react';
import MuiChip, { ChipProps as MuiChipProps } from '@material-ui/core/Chip';

import { makeStyles } from '@material-ui/core/styles';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import withGenerateClassName from '../../../themes/withGenerateClassName';

const useStyles = makeStyles(
  (globaltheme: any) => ({
    root: {
      height: 'auto',
      minHeight: '44px',
      boxSizing: 'border-box',
      fontSize: globaltheme.spacing(5),
      borderRadius: '5px',
      padding: globaltheme.spacing(2),
    },
    icon: {
      width: '36px',
      height: '36px',
      marginLeft: globaltheme.spacing(0),
    },
    leftIconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      borderRadius: '5px',
    },
    label: {
      whiteSpace: 'normal',
    },
    defaultVariantRoot: {
      color: globaltheme?.palette?.basic?.white,
      backgroundColor: globaltheme?.palette?.primary?.shade_800,
      '&.MuiChip-deletable:focus': {
        backgroundColor: globaltheme?.palette?.primary?.shade_800,
      },
    },
    fine_pine_outlined: {
      border: `1.5px solid ${globaltheme?.palette?.fine_pine?.main}`,
      color: globaltheme?.palette?.fine_pine?.main,
      backgroundColor: globaltheme?.palette?.fine_pine?.shade_50,
      '&.MuiChip-deletable:focus': {
        backgroundColor: globaltheme?.palette?.fine_pine?.shade_50,
      },
    },
    primary_outlined: {
      border: `1.5px solid ${globaltheme?.palette?.primary?.main}`,
      color: globaltheme?.palette?.primary?.main,
      backgroundColor: globaltheme?.palette?.primary?.shade_50,
      '&.MuiChip-deletable:focus': {
        backgroundColor: globaltheme?.palette?.primary?.shade_50,
      },
    },
    coral_red_outlined: {
      border: `1.5px solid ${globaltheme?.palette?.coral_red?.main}`,
      color: globaltheme?.palette?.basic?.danger_red,
      backgroundColor: globaltheme?.palette?.coral_red?.shade_50,
      '&.MuiChip-deletable:focus': {
        backgroundColor: globaltheme?.palette?.coral_red?.shade_50,
      },
    },
    butterscotch_yellow_outlined: {
      border: `1.5px solid ${globaltheme?.palette?.butterscotch_yellow?.main}`,
      color: globaltheme?.palette?.butterscotch_yellow?.main,
      backgroundColor: globaltheme?.palette?.butterscotch_yellow?.shade_50,
      '&.MuiChip-deletable:focus': {
        backgroundColor: globaltheme?.palette?.butterscotch_yellow?.shade_50,
      },
    },
    // Left Icon Wrapper variants
    fine_pine_default_left_icon_wrapper: {
      color: globaltheme?.palette?.basic?.white,
      backgroundColor: globaltheme?.palette?.fine_pine?.main,
    },
    fine_pine_outlined_left_icon_wrapper: {
      color: globaltheme?.palette?.fine_pine?.main,
      backgroundColor: 'inherit',
    },
    primary_default_left_icon_wrapper: {
      color: globaltheme?.palette?.basic?.white,
      backgroundColor: globaltheme?.palette?.primary?.main,
    },
    primary_outlined_left_icon_wrapper: {
      color: globaltheme?.palette?.primary?.main,
      backgroundColor: 'inherit',
    },
    coral_red_default_left_icon_wrapper: {
      color: globaltheme?.palette?.basic?.white,
      backgroundColor: globaltheme?.palette?.basic?.danger_red,
    },
    coral_red_outlined_left_icon_wrapper: {
      color: globaltheme?.palette?.basic?.danger_red,
      backgroundColor: 'inherit',
    },
    butterscotch_yellow_default_left_icon_wrapper: {
      color: globaltheme?.palette?.basic?.white,
      backgroundColor: globaltheme?.palette?.butterscotch_yellow?.main,
    },
    butterscotch_yellow_outlined_left_icon_wrapper: {
      color: globaltheme?.palette?.butterscotch_yellow?.main,
      backgroundColor: 'inherit',
    },
    // Delete icon variants
    deleteIcon: {
      color: globaltheme?.palette?.basic?.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        color: 'inherit',
      },
    },
    fine_pine_delete_icon: {
      color: globaltheme?.palette?.fine_pine?.main,
    },
    primary_delete_icon: {
      color: globaltheme?.palette?.primary?.main,
    },
    coral_red_delete_icon: {
      color: globaltheme?.palette?.basic?.danger_red,
    },
    butterscotch_yellow_delete_icon: {
      color: globaltheme?.palette?.butterscotch_yellow?.main,
    },
  }),
  { name: 'ComponentLibrary' }
);

type IconProps = {
  icon: string | undefined;
};

const Icon: FC<IconProps> = ({ icon = 'primary' }) => {
  return (
    {
      fine_pine: <CheckCircleOutlineRoundedIcon />,
      primary: <InfoOutlinedIcon />,
      coral_red: <ReportProblemOutlinedIcon />,
      butterscotch_yellow: <ReportProblemOutlinedIcon />,
    }[icon] || <CheckCircleOutlineRoundedIcon />
  );
};

interface ToastProps extends Omit<MuiChipProps, 'color'> {
  color: 'fine_pine' | 'primary' | 'coral_red' | 'butterscotch_yellow';
  toastClassName?: string;
  iconClassName?: string;
}

const Toast: FC<ToastProps> = ({
  color,
  variant,
  toastClassName,
  iconClassName,
  ...restProps
}) => {
  const classes = useStyles();

  const leftIcon = (
    <div
      className={[
        classes.leftIconWrapper,
        color && variant && classes[`${color}_${variant}_left_icon_wrapper`],
      ].join(' ')}
    >
      <Icon icon={color} />
    </div>
  );

  const deleteIcon = (
    <div
      className={[
        classes.deleteIcon,
        variant === 'outlined' && color && classes[`${color}_delete_icon`],
      ].join(' ')}
    >
      <CloseRoundedIcon />
    </div>
  );

  return (
    <MuiChip
      icon={leftIcon}
      deleteIcon={deleteIcon}
      classes={{
        root: [
          classes.root,
          variant === 'default' && classes.defaultVariantRoot,
          color && variant === 'outlined' && classes[`${color}_${variant}`],
          toastClassName,
        ].join(' '),
        icon: [classes.icon, iconClassName].join(' '),
        label: classes.label,
      }}
      {...restProps}
    />
  );
};

export default withGenerateClassName(Toast);
