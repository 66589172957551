import { FC } from 'react';
import classnames from 'classnames';

import Box from '@material-ui/core/Box';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';

import useStyles from './Textarea.styles';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import withGenerateClassName from '../../../themes/withGenerateClassName';

interface TextareaProps extends TextareaAutosizeProps {
  error?: boolean;
  label?: string;
  helperText?: string;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  innerRef?: React.Ref<HTMLTextAreaElement>;
  customClasses?: any;
}

const Textarea: FC<TextareaProps> = ({
  error,
  label,
  helperText,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  innerRef,
  customClasses = {},
  ...restProps
}) => {
  const classes = useStyles({
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    error,
  });

  return (
    <Box className={customClasses.wrapper}>
      {label && (
        <Typography
          variant="caption"
          className={classnames(classes.label, customClasses.label)}
        >
          {label}
        </Typography>
      )}

      <Box
        className={classnames(classes.innerWrapper, customClasses.innerWrapper)}
      >
        <TextareaAutosize
          ref={innerRef}
          className={classnames(classes.textarea, customClasses.textarea)}
          {...restProps}
        />
      </Box>

      {helperText && (
        <Box
          className={classnames(
            classes.helperTextWrapper,
            customClasses.helperTextWrapper
          )}
        >
          {error && (
            <WarningRoundedIcon
              preserveAspectRatio="none"
              viewBox="3 4 19.06 17.01"
              className={classnames(classes.errorIcon, customClasses.errorIcon)}
            />
          )}
          <Typography
            variant="caption"
            className={classnames(classes.helperText, customClasses.helperText)}
          >
            {helperText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default withGenerateClassName(Textarea);
