import { makeStyles } from '@material-ui/core/styles';

interface IStyleProps {
  isOpen?: boolean;
  width: string;
  // paperWidth?: string;
  size: 'thin' | 'small';
  showPlaceholder: boolean;
}

export default makeStyles(
  (theme) => ({
    picker_label: {
      fontSize: '14px',
      wordBreak: 'break-all',
      lineHeight: '17px',
      fontWeight: 300,
      color: theme?.palette?.secondary?.main,
      marginBottom: '8px',
      width: ({ width }: IStyleProps) => width,
    },
    anchor: {
      border: ({ isOpen }: IStyleProps) =>
        `1.5px solid ${
          isOpen
            ? theme?.palette?.primary?.main
            : theme?.palette?.secondary?.shade_200
        }`,
      borderRadius: '5px',
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 300,
      color: ({ showPlaceholder }: IStyleProps) =>
        showPlaceholder
          ? theme?.palette?.secondary?.shade_300
          : theme?.palette?.secondary?.main,
      boxSizing: 'border-box',
      width: ({ width }: IStyleProps) => width,
      height: ({ size }: IStyleProps) => (size === 'thin' ? '32px' : '40px'),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 9px 0 12px',
    },
    clock_icon: {
      color: `${theme?.palette?.basic?.black} !important`,
    },
    menu_paper: {
      height: '131px',
      // width: ({ paperWidth }: IStyleProps) => paperWidth,
      width: '250px',
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.12)',
      marginTop: '10px',
      borderRadius: '5px',
      overflow: 'hidden',
    },
    menu_content_container: {
      borderRadius: '5px',
    },
    menu_controls: {
      height: '99px',
      boxSizing: 'border-box',
      padding: '32px 14px 35px 14px',
      display: 'flex',
    },
    color_wrapper: {
      margin: '0 10px 0 7px',
      height: '32px',
      width: '4px',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    colon_dot: {
      width: '4px',
      height: '4px',
      background: '#C4C4C4',
      borderRadius: '50%',
    },
    minutes_dropdown_form_control: {
      marginRight: '19px',
    },
    apply_btn: {
      borderRadius: '0 0 5px 5px',
    },
    helper: {
      lineHeight: '14px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      fontFamily: theme?.typography?.fontFamily,
      width: ({ width }: IStyleProps) => width,
      wordBreak: 'break-all',
    },
    helperWrapper: {
      marginTop: '7.75px',
    },
    helperText: {
      fontSize: theme.spacing(4),
      color: theme?.palette?.secondary?.main,
    },
    errorWrapper: {
      marginTop: '7.28px',
      gap: theme.spacing(3),
    },
    errorIcon: {
      width: theme.spacing(5),
      height: '14px',
      color: theme?.palette?.basic?.danger_red,
    },
    errorText: {
      fontSize: '11px',
      color: theme?.palette?.basic?.danger_red,
    },
  }),
  { name: 'ComponentLibrary' }
);
