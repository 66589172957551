import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  error?: boolean;
}

const useStyles = makeStyles(
  (globaltheme: any) => ({
    label: {
      fontSize: '14px',
      marginBottom: '8.75px',
      lineHeight: '17px',
      boxSizing: 'border-box',
      display: 'block',
      color: globaltheme?.palette?.secondary?.main,
    },
    textarea: {
      color: globaltheme?.palette?.secondary?.main,
      border: ({ error }) =>
        `1.5px solid ${
          error
            ? globaltheme?.palette?.basic?.danger_red
            : globaltheme?.palette?.secondary?.shade_200
        }`,
      borderRadius: '5px',
      fontSize: globaltheme.spacing(5),
      padding: '10px 11px 19px 11px',
      lineHeight: '19px',
      boxSizing: 'border-box',
      outline: 'none !important', // required for border in focus state
      minWidth: ({ minWidth }: StyleProps) => minWidth || '478px',
      maxWidth: ({ maxWidth }: StyleProps) => maxWidth || 'none',
      minHeight: ({ minHeight }: StyleProps) => minHeight || 'none',
      maxHeight: ({ maxHeight }: StyleProps) => maxHeight || 'none',
      '&:hover': {
        background: globaltheme?.palette?.secondary?.shade_50,
      },
      '&:focus': {
        background: globaltheme?.palette?.basic?.white,
        border: ({ error }) =>
          `1.5px solid ${
            error
              ? globaltheme?.palette?.basic?.danger_red
              : globaltheme?.palette?.primary?.main
          }`,
      },
      fontFamily: globaltheme?.typography?.fontFamily,
    },
    helperTextWrapper: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      marginTop: '7.28px',
      gap: globaltheme.spacing(3),
    },
    helperText: {
      fontSize: '11px',
      lineHeight: '14px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      color: ({ error }) =>
        error
          ? globaltheme?.palette?.basic?.danger_red
          : globaltheme?.palette?.secondary?.main,
    },
    errorIcon: {
      width: globaltheme.spacing(5),
      height: '14px',
      color: globaltheme?.palette?.basic?.danger_red,
    },
    innerWrapper: {
      display: 'flex',
    },
  }),
  { name: 'ComponentLibrary' }
);
export default useStyles;
