import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme: any) => ({
    tabsRoot: {
      minHeight: 0,
    },
    tabRoot: {
      fontSize: '14px',
      lineHeight: '17px',
      minHeight: '0',
      minWidth: '0',
      textTransform: 'none',
    },
    outlined_root: {
      fontWeight: 500,
      color: theme?.palette?.secondary?.main,
      padding: '0 0 14px 0',
      // borderBottom: `2px solid ${theme?.palette?.secondary?.shade_100}`,
    },
    filled_primary_root: {
      fontWeight: 500,
      color: theme?.palette?.secondary?.main,
      border: `1.5px solid ${theme?.palette?.secondary?.shade_200}`,
      padding: '3.5px 12px 4.5px 12px',
      borderRadius: '5px',
    },
    filled_secondary_root: {
      color: theme?.palette?.primary?.main,
      background: theme?.palette?.primary?.shade_200,
      padding: '5px 12px 6px 12px',
      borderRadius: '5px',
      border: 'none',
    },
    outlined_selected: {
      color: theme?.palette?.primary?.main,
    },
    filled_primary_selected: {
      color: theme?.palette?.basic?.white,
      background: theme?.palette?.primary?.main,
      border: 'none',
    },
    filled_secondary_selected: {
      color: theme?.palette?.basic?.white,
      background: theme?.palette?.primary?.main,
      border: 'none',
    },
    outlined_indicator: {
      borderBottom: `2px solid ${theme?.palette?.primary?.main}`,
      background: 'none',
    },
    filled_primary_indicator: {
      background: 'none',
      minHeight: 0,
    },
    filled_secondary_indicator: {
      background: 'none',
      minHeight: 0,
    },
    outlined_flexContainer: {
      gap: '24px',
      borderBottom: `2px solid ${theme?.palette?.secondary?.shade_100}`,
    },
    filled_primary_flexContainer: {
      gap: '12px',
    },
    filled_secondary_flexContainer: {
      gap: '12px',
    },
    filled_primary_scroller: {
      borderBottom: `2px solid ${theme?.palette?.secondary?.shade_100}`,
      paddingBottom: '16px',
    },
    filled_secondary_scroller: {},
  }),
  { name: 'ComponentLibrary', index: 2 }
);
