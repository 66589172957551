import { makeStyles } from '@material-ui/core/styles';

interface IStyleProps {
  isOpen?: boolean;
}

export default makeStyles(
  (theme) => ({
    anchor_container: {
      border: ({ isOpen }: IStyleProps) =>
        `1px solid ${
          isOpen
            ? theme?.palette?.primary?.main
            : theme?.palette?.secondary?.shade_200
        }`,
      borderRadius: '5px',
      boxSizing: 'border-box',
      width: '60px',
      height: '32px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    anchor_content: {
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 500,
      flexGrow: 1,
      paddingLeft: '12px',
      paddingTop: '7px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    anchor_actions: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      height: '32px',
      width: '21.41px',
    },
    anchor_action_btn_up: {
      padding: '0',
      position: 'absolute',
      top: '8px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& svg': {
        height: '4.49px',
      },
    },
    anchor_action_btn_down: {
      padding: '0',
      position: 'absolute',
      bottom: '8px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& svg': {
        height: '4.49px',
      },
    },
    anchor_action_svg: {
      color: theme?.palette?.secondary?.main,
    },
    menu_paper: {
      background: theme?.palette?.basic?.white,
      border: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      boxShadow: 'none',
      width: '31px',
      height: '99px',
      boxSizing: 'border-box',
      borderRadius: '5px',
      position: 'relative',
      padding: '17px 0',
      overflow: 'hidden',
    },
    menu_action_btn_up: {
      position: 'absolute',
      padding: '0',
      top: '-4.75px',
      left: '0',
      right: '0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    menu_action_btn_down: {
      position: 'absolute',
      padding: '0',
      bottom: '-4.34px',
      left: '0',
      right: '0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    menu_list: {
      height: '63px',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    menu_item: {
      color: theme?.palette?.secondary?.main,
      height: '21px',
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 500,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    menu_item_selected: {
      background: theme?.palette?.primary?.shade_200,
    },
  }),
  { name: 'ComponentLibrary' }
);
