import React, { useState, useRef } from 'react';
import Popover from '@material-ui/core/Popover';
import classnames from 'classnames';

import useStyles from './TimePicker.styles';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { formatAMPM, amPmOptions } from './TimePicker.utils';
import withGenerateClassName from '../../../themes/withGenerateClassName';

import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import CountPicker from './components/CountPicker/CountPicker';

interface ITimePickerClasses {
  label?: string;
  anchor?: string;
  helperText?: string;
  errorHelperText?: string;
  paper?: string;
  menuContent?: string;
  menuControls?: string;
}

interface IPlacehoder {
  date: string;
  hours: string;
  minutes: string;
  ampm: string;
}

interface ITimePickerProps {
  value: string;
  onChange: any;
  size?: 'thin' | 'small';
  fullWidth?: boolean;
  width?: string;
  label?: string;
  customClasses?: ITimePickerClasses;
  placeholder: IPlacehoder;
  error?: boolean;
  helperText?: string;
}

const TimePicker: React.FC<ITimePickerProps> = ({
  value,
  onChange,
  width = '250px',
  fullWidth,
  size = 'thin',
  label,
  customClasses = {},
  placeholder = {} as IPlacehoder,
  error,
  helperText,
}) => {
  const [popoverAnchor, setPopoverAnchor] = useState<
    null | Element | ((element: Element) => Element)
  >(null);
  // const anchorRef = useRef(null);
  // const anchorWidth = usePopoverResizer(anchorRef);

  const isOpen = Boolean(popoverAnchor);
  const classes = useStyles({
    isOpen,
    size,
    width: fullWidth ? '100%' : width,
    showPlaceholder: !value,
    // paperWidth: fullWidth ? anchorWidth : width,
  });

  const [selectedHours, setSelectedHours] = useState('');
  const [selectedMinutes, setSelectedMinutes] = useState('');
  const [selectedAmPm, setSelectedAmPm] = useState('');

  const onOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setPopoverAnchor(event.currentTarget);
    const { hours, minutes, ampm } = formatAMPM(value);
    setSelectedHours(hours);
    setSelectedMinutes(minutes);
    setSelectedAmPm(ampm);
  };

  const onClose = () => {
    setPopoverAnchor(null);
  };

  const onApply = () => {
    const hrs = String(selectedHours).padStart(2, '0');
    const mins = String(selectedMinutes).padStart(2, '0');
    onChange(`${hrs}:${mins} ${selectedAmPm}`);
    setPopoverAnchor(null);
  };

  return (
    <>
      {label && (
        <div className={classnames(classes.picker_label, customClasses.label)}>
          {label}
        </div>
      )}
      <div
        role="button"
        onClick={onOpen}
        // ref={anchorRef}
        className={classnames(classes.anchor, customClasses.anchor)}
      >
        {value || placeholder.date}
        <AccessTimeRoundedIcon className={classes.clock_icon} />
      </div>

      {helperText && (
        <div
          className={[
            classes.helper,
            classes[error ? 'errorWrapper' : 'helperWrapper'],
            customClasses.helperText,
          ].join(' ')}
        >
          {error && (
            <WarningRoundedIcon
              preserveAspectRatio="none"
              viewBox="3 4 19.06 17.01"
              className={[classes.errorIcon, customClasses.errorIcon].join(' ')}
            />
          )}
          <span
            className={[
              classes[error ? 'errorText' : 'helperText'],
              customClasses.errorHelperText,
            ].join(' ')}
          >
            {helperText}
          </span>
        </div>
      )}

      <Popover
        anchorEl={popoverAnchor}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classnames(classes.menu_paper, customClasses.paper) }}
      >
        <div
          className={classnames(
            classes.menu_content_container,
            customClasses.menuContent
          )}
        >
          <div
            className={classnames(
              classes.menu_controls,
              customClasses.menuControls
            )}
          >
            <CountPicker
              value={selectedHours}
              onChange={setSelectedHours}
              start={1}
              end={12}
              placeholder={placeholder.hours}
            />

            <div className={classes.color_wrapper}>
              <div className={classes.colon_dot} />
              <div className={classes.colon_dot} />
            </div>

            <CountPicker
              value={selectedMinutes}
              onChange={setSelectedMinutes}
              className={classes.minutes_dropdown_form_control}
              start={0}
              end={59}
              placeholder={placeholder.minutes}
            />
            <Dropdown
              value={selectedAmPm}
              handleChange={setSelectedAmPm}
              options={amPmOptions}
              size="thin"
              placeholder={placeholder.ampm}
            />
          </div>
          <Button
            color="primary"
            size="thin"
            fullWidth
            className={classes.apply_btn}
            onClick={onApply}
            disabled={
              !(
                selectedHours &&
                selectedAmPm &&
                (selectedMinutes || Number(selectedMinutes) === 0)
              )
            }
          >
            Apply
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default withGenerateClassName(TimePicker);
