import MuiTabs, { TabsProps as MuiTabsProps } from '@material-ui/core/Tabs';
import MuiTab, { TabProps as MuiTabProps } from '@material-ui/core/Tab';

import classnames from 'classnames';

import useStyles from './Tabs.styles';
import withGenerateClassName from '../../../themes/withGenerateClassName';

interface ITabsProps extends MuiTabsProps {
  tabs: MuiTabProps[];
  tabVariant: 'outlined' | 'filled_primary' | 'filled_secondary';
  tab_classes?: any;
}

function Tabs({
  tabs = [],
  tabVariant = 'outlined',
  classes: tabs_classes,
  tab_classes,
  ...restProps
}: ITabsProps) {
  const classes = useStyles();
  const {
    root: tabs_root,
    indicator: tabs_indicator,
    flexContainer: tabs_flexContainer,
    scroller: tabs_scroller,
    ...rest_tabs_classes
  } = tabs_classes || {};

  const {
    root: tab_root,
    selected: tab_selected,
    wrapper: tab_wrapper,
    ...rest_tab_classes
  } = tab_classes || {};

  return (
    <MuiTabs
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      classes={{
        root: classnames(classes.tabsRoot, tabs_root),
        indicator: classnames(
          classes[`${tabVariant}_indicator`],
          tabs_indicator
        ),
        flexContainer: classnames(
          classes[`${tabVariant}_flexContainer`],
          tabs_flexContainer
        ),
        scroller: classnames(classes[`${tabVariant}_scroller`], tabs_scroller),
        ...rest_tabs_classes,
      }}
      {...restProps}
    >
      {tabs?.map((tabItem, index) => (
        <MuiTab
          classes={{
            root: classnames(
              classes.tabRoot,
              classes[`${tabVariant}_root`],
              tab_root
            ),
            selected: classnames(
              classes[`${tabVariant}_selected`],
              tab_selected
            ),
            wrapper: classnames(tab_wrapper),
            ...rest_tab_classes,
          }}
          disableRipple
          key={`tab__${index}__${tabItem?.label}__${tabItem?.value}`}
          {...tabItem}
        />
      ))}
    </MuiTabs>
  );
}

export default withGenerateClassName(Tabs);
