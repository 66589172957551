export function formatAMPM(dateValue: string) {
  if (!dateValue) return { hours: '', minutes: '', ampm: '' };
  const [count, ampm] = dateValue.split(' ');
  const [hours, minutes] = count.split(':');
  return { hours, minutes, ampm };
}

export const generateCountConfig = (start: number, end: number) => {
  const countConfig = [];
  for (let value = start; value <= end; value++) {
    countConfig.push({
      id: value,
      value: value,
      label: String(value).padStart(2, '0'),
    })
  }
  return countConfig;
}

export const amPmOptions = [
  { id: "am", label: "AM", value: "am" },
  { id: "pm", label: "PM", value: "pm" },
]