import { FC } from 'react';

import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';
import withGenerateClassName from '../../../themes/withGenerateClassName';

const useStyles = makeStyles(
  (globaltheme: any) => ({
    tooltip: {
      fontSize: '14px',
    },
    primary: {
      color: globaltheme?.palette?.basic?.white,
      backgroundColor: globaltheme?.palette?.primary?.shade_800,
    },
    secondary: {
      color: globaltheme?.palette?.secondary?.main,
      backgroundColor: globaltheme?.palette?.basic?.white,
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.078)',
    },
    primaryArrow: {
      color: globaltheme?.palette?.primary?.shade_800,
    },
    secondaryArrow: {
      color: globaltheme?.palette?.basic?.white,
    },
  }),
  { name: 'ComponentLibrary' }
);

interface TooltipProps extends MuiTooltipProps {
  color: 'primary' | 'secondary';
  tooltipClassName?: string;
  arrowClassName?: string;
}

const Tooltip: FC<TooltipProps> = ({
  children,
  color,
  tooltipClassName,
  arrowClassName,
  classes: classesProp,
  ...props
}) => {
  const classes = useStyles();
  return (
    <MuiTooltip
      classes={{
        tooltip: [
          classes.tooltip,
          color && classes[color],
          tooltipClassName,
        ].join(' '),
        arrow: [classes[`${color}Arrow`], arrowClassName].join(' '),
        tooltipPlacementBottom: classesProp?.tooltipPlacementBottom,
        ...classesProp,
      }}
      enterTouchDelay={0}
      leaveTouchDelay={600000}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
};

export default withGenerateClassName(Tooltip);
